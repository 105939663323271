$(document).ready(function() {

    $('select').material_select();
    $('.parallax').parallax();
    $('ul.tabs').tabs();

    $('#navegador').css({'display':'none'});
    $(window).scroll(function () {
        if ($(window).scrollTop() > 480) {
            $('#navegador').css({'display':'block'});
            $('#navegador').addClass('navbar-fixed');
        }
        if ($(window).scrollTop() < 481) {
            $('#navegador').css({'display':'none'});
            $('#navegador').removeClass('navbar-fixed');
        }
    });

});